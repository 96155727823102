import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const EmailIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M4 4.46094H20C21.1 4.46094 22 5.36094 22 6.46094V18.4609C22 19.5609 21.1 20.4609 20 20.4609H4C2.9 20.4609 2 19.5609 2 18.4609V6.46094C2 5.36094 2.9 4.46094 4 4.46094Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M22 6.46094L12 13.4609L2 6.46094" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};