import { styled, List } from "@mui/material";

export const ListWrapper = styled(List)`
  padding-top: 0;
  padding-bottom: 0;
  
  .MuiListItem-root{
    padding: 24px;
    position: relative;
    background-color: #1F1F1F;
    border-radius: 8px;
    gap: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    color: #c0c0c0;

    @media (min-width: 992px){
      font-size: 18px;
    }

    + .MuiListItem-root{
      margin-top: 14px;
    }

    .emphasized &{
      color: #e9e9e9;
      background-color: #131313;
    }
  }

  .MuiListItemIcon-root{
    min-width: 1px;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 13px;
    color: #E3C050;
  }

  .MuiTypography-root{
    font: inherit;
  }

  .list-item-heading{
    flex-shrink: 0;
    color: #F2F1F3;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5;

    @media (min-width: 768px){
      width: 200px;
    }
    @media (min-width: 992px){
      font-size: 24px;
      width: 250px;
    }
  }

  .bordered &{

    .MuiListItem-root{
      background-color: transparent;
      border-radius: 0;
      padding: 35px 0;
      border: solid #373737;
      border-width: 1px 0;
      margin-bottom: -1px;

      + .MuiListItem-root{
        margin-top: 0;
      }
    }
  }

  .featured &{

    .MuiListItem-root{
      font-weight: 400;
      flex-direction: column;
      align-items: flex-start;

      @media (min-width: 768px){
        flex-direction: row;
        align-items: center;
      }
    }
  }
`;