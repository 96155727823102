import { styled } from '@mui/material';

export const Wrapper = styled('div')`
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 24px;

> div{
  width: 100%;

  @media (min-width: 576px){
    width: calc(50% - 18px);
  }
  @media (min-width: 992px){
    width: calc(25% - 18px);
  }
}
`;