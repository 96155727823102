import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const PlayIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="46"
      height="66"
      viewBox="0 0 46 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M45.04 34.4234L2.47516 65.392C2.24181 65.5597 1.96675 65.6598 1.6802 65.6813C1.39366 65.7029 1.10672 65.645 0.850923 65.5141C0.595126 65.3831 0.380374 65.1843 0.230257 64.9392C0.0801408 64.6942 0.00047069 64.4125 8.38846e-06 64.1252V2.22687C-0.00093595 1.93894 0.0778693 1.65637 0.227687 1.41049C0.377505 1.1646 0.592482 0.965007 0.848793 0.833824C1.1051 0.702642 1.39273 0.644992 1.6798 0.667273C1.96687 0.689554 2.24217 0.790895 2.47516 0.960056L45.04 31.9092C45.2411 32.0514 45.4051 32.2398 45.5184 32.4585C45.6316 32.6773 45.6907 32.92 45.6907 33.1663C45.6907 33.4126 45.6316 33.6553 45.5184 33.874C45.4051 34.0927 45.2411 34.2811 45.04 34.4234Z" fill="currentColor"/>
    </SvgIcon>
  );
};