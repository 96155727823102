import { styled, Box, Button, Typography } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 1200px){
    padding-left: 30px;
  }
`;

export const VideoRow = styled(Box)`
  display: flex;
  width: 100%;
  gap: 24px;
`;

export const ImageWrapper = styled(Box)`
  width: 94px;
  height: 65px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  border: 1px solid rgba(31, 31, 31, 1);
  position: relative;

  @media (min-width: 768px){
    width: 124px;
    height: 75px;
  }

  &:before{
    pointer-events: none;
    z-index: 1;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, rgba(0, 0, 0,0.6) 0%,rgba(0, 0, 0,0.1) 100%);
  }
  
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
`;

export const DescriptionWrapper = styled(Box)`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
`;

export const Align = styled(Box)`
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;

  @media (min-width: 768px){
    font-size: 18px;
  }
`;

export const CaptionHeading = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #C0C0C0;

  @media (min-width: 768px){
    font-size: 18px;
  }
`;

export const PlayButton = styled(Button)`
  position: absolute;
  right: 8px;
  bottom: 8px;
  z-index: 3;
  border-radius: 100%;
  background-color: rgba(13, 13, 24, 0.5);
  width: 20px;
  height: 20px;
  border: 1px solid rgb(75, 75, 88);
  padding: 0;
  min-width: 1px;
  color: #fff;

  &:hover{
    background-color: rgb(75, 75, 88);
  }

  .MuiSvgIcon-root{
    margin-left: 1px;
    width: 6px;
    height: 6px;
  }
`;