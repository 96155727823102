import { AuthorHeading, Cite, CiteWrapper, DescriptionWrapper, ImageHolder, LogoWrapper, QuoteIconWrapper, TextWrapper, Wrapper } from "./style";
import IconQuote from "../../assets/images/iconQuote.png"
import IconLogo from "../../assets/images/img-icon-17.svg"
import CardBox from "components/CardBox";

const QuoteColumn = ({ImageUrl, text, author, cite}: any) => (
  <CardBox>
    <Wrapper>
      <ImageHolder>
        <img src={ImageUrl} alt='' />
      </ImageHolder>
      <DescriptionWrapper>
        <QuoteIconWrapper>
          <img src={IconQuote} alt="" />
        </QuoteIconWrapper>
        <TextWrapper>
          {text}
        </TextWrapper>
        <CiteWrapper>
          {author &&
            <AuthorHeading
              variant="h3"
            >
              {author}
            </AuthorHeading>
          }
          {cite &&
            <Cite
              variant="caption"
            >
              {cite}
            </Cite>
          }
          <LogoWrapper>
            <img src={IconLogo} alt="" width="73" height="44" />
          </LogoWrapper>
        </CiteWrapper>
      </DescriptionWrapper>
    </Wrapper>
  </CardBox>
);

export default QuoteColumn;