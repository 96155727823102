import CardBox from "components/CardBox";
import { Col, Heading, ListWrapper, Wrapper } from "./style";
import { ListItem, ListItemText } from "@mui/material";

const ComparisonListColumns = ({}: any) => (
  <CardBox>
    <Wrapper>
      <Col>
        <Heading
          variant="h3"
        >
          Under consideration
        </Heading>
        <ListWrapper>
          <ListItem>
            <ListItemText
              primary="Investor heatmaps"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Investor surveys"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Integration with account based marketing providers"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Integration with 6ix Promo"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Session recordings"
            />
          </ListItem>
        </ListWrapper>
      </Col>
      <Col>
        <Heading
          variant="h3"
        >
          In progress
        </Heading>
        <ListWrapper>
          <ListItem>
            <ListItemText
              primary="Embed VRIFY maps in 1-click"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Connect your website hosting provider in 1-click"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Connect your email service provider in 1-click"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Get investor alerts based on behavioural activities"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Select between different website themes & designs"
            />
          </ListItem>
        </ListWrapper>
      </Col>
    </Wrapper>
  </CardBox>
);

export default ComparisonListColumns;