import { styled, Typography, Box } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
`;

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1091px;
  margin-left: auto;
  margin-right: auto;
`;

export const AlignHolder = styled(Box)`

  @media (min-width: 768px){
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
  }
  @media (min-width: 992px){
    margin-left: -28px;
    margin-right: -28px;
    width: calc(100% + 56px);
  }
`;

export const Col = styled(Box)`

  @media (min-width: 768px){
    display: flex;
    width: 100%;
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (min-width: 992px){
    padding-left: 28px;
    padding-right: 28px;
  }
`;

export const AlignBox = styled(Box)`
  width: 100%;

  @media (min-width: 768px){
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export const ImageHolder = styled(Box)`
  margin: 30px auto 0;
  width: 100%;

  @media (min-width: 768px){
    margin: 0;
  }

  img{
    max-width: 100%;
    height: auto;
  }
`;

export const Heading = styled(Typography)`
  font: 800 30px/1.27 'Playfair Display', 'Times New Roman', Times, serif;
  margin-bottom: 24px;

  @media (min-width: 768px){
    font-size: 36px;
  }
  @media (min-width: 992px){
    font-size: 40px;
  }
  @media (min-width: 1200px){
    font-size: 44px;
  }
`;

export const Description = styled(Typography)`
  color: #C0C0C0;
  font-size: 16px;
  line-height: 1.5;

  @media (min-width: 768px){
    font-size: 18px;
  }
  @media (min-width: 992px){
    font-size: 20px;
  }

  &:not(:last-child){
    margin-bottom: 30px;
  }
`;