import {
  CalendarLeft,
  ContentLoader,
  ContentList,
  ContentListItem,
  ContentListItemIcon,
  ContentListItemText,
} from "./style";

export const CalenderLeftLoader = () => {
  return (
    <CalendarLeft>
      <ContentLoader></ContentLoader>
      <ContentLoader className="paragraph"></ContentLoader>
      <ContentList>
        <ContentListItem>
          <ContentListItemIcon>
            <ContentLoader className="icon"></ContentLoader>
          </ContentListItemIcon>
          <ContentListItemText>
            <ContentLoader className="mb"></ContentLoader>
          </ContentListItemText>
        </ContentListItem>
        <ContentListItem>
          <ContentListItemIcon>
            <ContentLoader className="icon"></ContentLoader>
          </ContentListItemIcon>
          <ContentListItemText>
            <ContentLoader className="mb"></ContentLoader>
          </ContentListItemText>
        </ContentListItem>
      </ContentList>
    </CalendarLeft>
  );
};

