import { Box, Typography } from "@mui/material";
import { Wrapper, Section } from "./style";
import SectionHeader from "components/SectionHeader";
import ThreeColumnsGrid from "components/ThreeColumnsGrid";
import CardBox from "components/CardBox";
import BlogColumn from "components/BlogColumn";
import Img1 from "../../assets/images/img02.jpg"
import Img2 from "../../assets/images/img03.jpg"
import Img3 from "../../assets/images/img04.jpg"

const BlogsSection = ({pTop, pBottom, bgColor}: any) => (
  <Section
    className="BlogsSection"
    sx={{
      backgroundColor: `${bgColor}`,
      paddingTop: `calc(${pTop} * 0.52)`,
      paddingBottom: `calc(${pBottom} * 0.52)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <SectionHeader
        heading="Reach investors with 6ix Leads"
        tagline="Case Studies"
      />
      <ThreeColumnsGrid>
        <CardBox>
          <BlogColumn imageUrl={Img1} heading="EV Nickel" excerpt="6ix made sure our website was easy to read, easy to update and informative for investors." />
        </CardBox>
        <CardBox>
          <BlogColumn imageUrl={Img2} heading="PowerOne Capital Markets" excerpt="The new web stack developed by 6ix makes it simple & easy for us to keep our deal flow up to date." />
        </CardBox>
        <CardBox>
          <BlogColumn imageUrl={Img3} heading="Chesapeake Gold" excerpt="6ix helped make it easy to keep our website up to date and running smoothly." />
        </CardBox>
      </ThreeColumnsGrid>
    </Wrapper>
  </Section>
);

export default BlogsSection;