import { Align, CaptionHeading, DescriptionWrapper, Heading, PlayButton, Wrapper, ImageWrapper, VideoRow } from "./style";
import { PlayIcon } from "components/Icons/PlayIcon";
import Image1 from "../../assets/images/img32.jpg"
import Image2 from "../../assets/images/img33.jpg"
import Image3 from "../../assets/images/img34.jpg"
import Image4 from "../../assets/images/img35.jpg"

const VideosList = ({}: any) => (
  <Wrapper>
    <VideoRow>
      <ImageWrapper>
        <img src={Image1} alt="" />
        <PlayButton>
          <PlayIcon />
        </PlayButton>
      </ImageWrapper>
      <DescriptionWrapper>
        <Align>
          <Heading
            variant="h3"
          >
            6 IR Dos and Donts
          </Heading>
          <CaptionHeading
            variant="h4"
          >
            Episode 2
          </CaptionHeading>
        </Align>
      </DescriptionWrapper>
    </VideoRow>
    <VideoRow>
      <ImageWrapper>
        <img src={Image2} alt="" />
        <PlayButton>
          <PlayIcon />
        </PlayButton>
      </ImageWrapper>
      <DescriptionWrapper>
        <Align>
          <Heading
            variant="h3"
          >
            The Elements of a Great Investor Website
          </Heading>
          <CaptionHeading
            variant="h4"
          >
            Episode 3
          </CaptionHeading>
        </Align>
      </DescriptionWrapper>
    </VideoRow>
    <VideoRow>
      <ImageWrapper>
        <img src={Image3} alt="" />
        <PlayButton>
          <PlayIcon />
        </PlayButton>
      </ImageWrapper>
      <DescriptionWrapper>
        <Align>
          <Heading
            variant="h3"
          >
            Giving Investor Relations Professionals Superpowers
          </Heading>
          <CaptionHeading
            variant="h4"
          >
            Episode 4
          </CaptionHeading>
        </Align>
      </DescriptionWrapper>
    </VideoRow>
    <VideoRow>
      <ImageWrapper>
        <img src={Image4} alt="" />
        <PlayButton>
          <PlayIcon />
        </PlayButton>
      </ImageWrapper>
      <DescriptionWrapper>
        <Align>
          <Heading
            variant="h3"
          >
            How to use Integrate Your 6ix Website with SEDAR, EDGAR and AI
          </Heading>
          <CaptionHeading
            variant="h4"
          >
            Episode 5
          </CaptionHeading>
        </Align>
      </DescriptionWrapper>
    </VideoRow>
  </Wrapper>
);

export default VideosList;