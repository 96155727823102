import CardBox from "components/CardBox";
import { Description, Heading, Hr, ImageHolder, Wrapper } from "./style";

const BlogColumn = ({imageUrl, heading, excerpt}: any) => (
  <Wrapper>
    <ImageHolder>
      <img src={imageUrl} alt={heading} />
    </ImageHolder>
    <Heading
      variant="h3"
    >
      {heading}
    </Heading>
    {excerpt &&
      <>
        <Hr />
        <Description
          variant="body1"
        >
          {excerpt}
        </Description>
      </>
    }
  </Wrapper>
);

export default BlogColumn;