import { CheckMarkIcon } from "components/Icons/CheckMarkIcon";
import {
  BottomFoot,
  BottomWrap,
  ButtonDefault,
  ButtonPrimary,
  CalendarWrapper,
  DatePickerWrapper,
  FormInlineWrapper,
  Heading,
  InfoLineText,
  InfoWidgetWrapper,
  InnerWrapper,
  LabelHeading,
  PickWrapper,
  SelectField,
  SideWrapper,
  Subheading,
  TextNote,
  TimeWrapper,
  TopWrap,
  Wrapper,
  TogglerGroupWrapper,
  TogglerGroupButton,
  FormField,
  TextAreaField,
  ScheduleSuccessWrapper,
  HeadIcn,
  HeadWrap,
  HeadHeading,
  HeadDescr,
  CustomDivider,
  InfoList,
  ListDescr,
  ListTitle,
  ButtonLink,
  TextBack,
  WrapFoot,
  IcnWrap,
  SimpleSelectField,
  ScatList,
  DaterWrapper,
  NexterWrap,
  FormGroup,
  InlineProfilerWrap,
  DragButton,
  InfoPicWrap,
  SpanCheck,
  InfoText,
  ButtonTertiary,
  DescrRow,
  InfoTextDescr,
  TextBold,
  TextSubtitle,
  ListIcons,
  ButtonSelector,
  Icn,
  DescrTag,
  TextSelectedDate,
  TuneTimeSelect,
  TuneScrollWrap,
} from "./style";
import { CalendarIcon } from "components/Icons/CalendarIcon";
import { TimeClockIcon } from "components/Icons/TimeClockIcon";
import { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import baseApi from "api/baseApi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { formatedDateStringCalender, formattedDateString } from "utils/helper";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import TimezoneSelect from "react-timezone-select";
import { ChipDeleteIcon } from "components/Icons/ChipDeleteIcon";
import { GlobeIcon } from "components/Icons/GlobeIcon";
import { DragMoveIcon } from "components/Icons/DragMoveIcon";
import { CheckIcon } from "components/Icons/CheckIcon";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import moment from "moment";
import momentTz from "moment-timezone";
import { EventInfoGoogle } from "components/RescheduleEventSection/EventInfoGoogle";
import { CancelEvent } from "components/RescheduleEventSection/CancelEvent";
import { AppContext } from "context";
import baseApiAvoidPreflight from "api/baseApiAvoidPreflight";
import { useQuery } from "utils/useQuery";
import { EventInfoOutlook } from "components/RescheduleEventSection/EventInfoOutlook";
import InvalidMeetingPage from "components/InvalidMeetingPage";
import { CalenderLeftLoader } from "components/Loader/CalenderLeftLoader";
import { CalenderRightLoader } from "components/Loader/CalenderRightLoader";

export default function RescheduleBookingSection({}: any) {
  const query = useQuery();
  const { eventId, userId, orgId, username } = useParams();
  const [step, setStep] = useState(1);
  const [startDate, setStartDate] = useState<any>();
  const [startTime, setStartTime] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { universalData } = useContext(AppContext);
  const navigate = useNavigate();

  const [bookings, setBookings] = useState<any>();
  const [refetch, setRefetch] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [timeSlotFetching, setTimeSlotFetching] = useState(false);

  const [availableSlots, setAvailableSlots] = useState<any>();
  const [events, setEvents] = useState<any>();

  const [eventInfo, setEventInfo] = useState<any>();
  const [eventData, setEventData] = useState<any>();

  const { register, handleSubmit, control, watch, setValue, reset } = useForm({
    defaultValues: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      duration: "30",
    } as any,
  });

  const resetHandler = () => {
    // setStartDate(undefined);
    // setStartTime(undefined);
    // reset();
    // setEventInfo(undefined);
    // setStep(1);
    // navigate("/" + username);
    window.location.href = "https://6ix.com";
  };

  const event = eventData;

  const booking = useMemo(
    () => bookings?.find((item: any) => item.eventid === eventId),
    [bookings]
  );

  const handleCancel = () => {
    if (eventInfo.id) {
      baseApiAvoidPreflight
        .post("/api/delete/events/" + eventInfo.id)
        .then((res) => {
          setStep(4);
        })
        .catch((err) => toast.error(err?.response?.data?.error));
    }
  };

  const onSubmit = (data: any) => {
    if (startDate) {
      setLoading(true);
      const [hours, minutes] = startTime.split(":");

      const newStartDate = new Date(startDate);
      const da = `${newStartDate.getFullYear()}-${
        newStartDate.getMonth() + 1 < 10
          ? `0${newStartDate.getMonth() + 1}`
          : newStartDate.getMonth() + 1
      }-${
        newStartDate.getDate() < 10
          ? `0${newStartDate.getDate()}`
          : newStartDate.getDate()
      }T${hours}:${minutes}`;

      const specificDate = momentTz.tz(da, data.timezone);
      const start = specificDate.utc().format();

      specificDate.add(parseInt(event?.duration), "minutes");

      const end = specificDate.utc().format();

      baseApiAvoidPreflight
        .post(
          availableSlots.calendar_type === "Google"
            ? "/api/reschedule/events/" + booking.eventid
            : "/api/ms/reschedule",
          {
            // timezone: data.timezone.value || data.timezone,
            eventId: eventId,
            eventTypeId: booking?.eventTypeId,
            newStartTime: start,
            newEndTime: end,
          }
        )
        .then((res) => {
          setStep((prev) => prev + 1);
          setEventInfo(res.data?.updatedEvent || res.data?.EventInfo);
          toast.success(res.data?.message);
        })
        .catch((err) => toast.error(err?.response?.data?.error))
        .finally(() => setLoading(false));
    }
  };

  const thirtySlots = useMemo(
    () => availableSlots?.thirtyMinuteSlots,
    [availableSlots]
  );

  useEffect(() => {
    if (userId && orgId) {
      baseApiAvoidPreflight
        .post(`/api/fetch/bookings/${userId}`, {
          orgId: orgId,
        })
        .then((res) => {
          if (res.data.bookings.length > 0) {
            setBookings(res.data.bookings);
          } else {
            navigate("/" + username);
          }
        })
        .catch((err) => toast.error(err?.response?.data?.error));
    }
  }, [userId, orgId, navigate, username]);

  useEffect(() => {
    if (booking?.uid && booking?.status === "Active") {
      setTimeSlotFetching(true);
      baseApiAvoidPreflight
        .get("/api/getavailabilityslots/findslot", {
          params: {
            userId: booking?.uid,
            orgId: orgId,
            _id: booking?.eventTypeId,
            // eventId: eventId,
            // timeslot: event?.duration,
            requestedzone: watch().timezone,
          },
        })
        .then((res) => {
          setAvailableSlots(res.data);
          setStartDate(dayjs(res.data.thirtyMinuteSlots[0].date).format());
        })
        .catch((err) => toast.error(err?.response?.data?.error))
        .finally(() => setTimeSlotFetching(false));
    }
  }, [watch().timezone, booking]);

  useEffect(() => {
    if (username && booking?.eventTypeId) {
      setFetching(true);
      baseApiAvoidPreflight
        .get("/api/" + username)
        .then((res) => {
          const fd = res.data.events.find(
            (ev: any) => ev._id === booking.eventTypeId
          );
          setEventData(fd);
        })
        .catch((err) => toast.error(err?.response?.data?.error))
        .finally(() => setFetching(false));
    }
  }, [booking?.eventTypeId, username]);

  return (
    <>
      {booking && booking?.status !== "Active" ? (
        <InvalidMeetingPage />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {step < 2 && (
            <Wrapper>
              {event ? (
                <SideWrapper>
                  <TopWrap>
                    <Subheading variant="caption">{event?.title}</Subheading>
                    <Heading variant="h2" className="meeting-description">
                      {event?.description}
                    </Heading>
                    <ScatList>
                      <ListItem>
                        <IcnWrap>
                          <TimeClockIcon />
                        </IcnWrap>
                        {event?.duration + " mins"}
                      </ListItem>
                      <ListItem>
                        <IcnWrap>
                          <GlobeIcon />
                        </IcnWrap>
                        <SimpleSelectField className="timezone">
                          <Controller
                            control={control}
                            defaultValue={
                              Intl.DateTimeFormat().resolvedOptions().timeZone
                            }
                            name="timezone"
                            render={({ field: { onChange, value } }) => (
                              <TimezoneSelect
                                className="timezone-select-container"
                                classNamePrefix="timezone"
                                value={value}
                                onChange={(e: any) => onChange(e.value)}
                              />
                            )}
                          />
                        </SimpleSelectField>
                      </ListItem>
                    </ScatList>
                  </TopWrap>
                </SideWrapper>
              ) : (
                <CalenderLeftLoader />
              )}
              <PickWrapper>
                <TopWrap>
                  {thirtySlots ? (
                    <InnerWrapper>
                      {step === 1 && (
                        <DaterWrapper className="date-time-pm">
                          {timeSlotFetching && (
                            <Box className="loader-holder">
                              <CircularProgress color="inherit" />
                            </Box>
                          )}
                          <DatePickerWrapper>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DateTimePicker"]}>
                                <DatePicker
                                  inline
                                  open
                                  selected={startDate}
                                  onChange={(date: any) => setStartDate(date)}
                                  includeDates={thirtySlots?.map(
                                    (slot: any) => (dayjs(slot.date) as any).$d
                                  )}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </DatePickerWrapper>
                          {startDate && (
                            <TuneTimeSelect>
                              <TextSelectedDate variant="caption">
                                {/* {formattedDateString(startDate)} */}
                                {formatedDateStringCalender(startDate)}
                              </TextSelectedDate>
                              <TuneScrollWrap>
                                {thirtySlots
                                  ?.filter(
                                    (slot: any) =>
                                      formattedDateString(slot.date) ===
                                      formattedDateString(startDate)
                                  )
                                  ?.map((sl: any) => (
                                    <div>
                                      <Button
                                        variant={
                                          startTime === sl.startTime
                                            ? "outlined"
                                            : "text"
                                        }
                                        onClick={() =>
                                          setStartTime(sl.startTime)
                                        }
                                      >
                                        {moment(sl.startTime, "h mm").format(
                                          "LT"
                                        )}
                                      </Button>
                                    </div>
                                  ))}
                              </TuneScrollWrap>
                            </TuneTimeSelect>
                          )}
                        </DaterWrapper>
                      )}
                    </InnerWrapper>
                  ) : (
                    <CalenderRightLoader />
                  )}
                </TopWrap>

                {step === 1 && (
                  <BottomFoot>
                    <>
                      <ButtonDefault
                        type="button"
                        onClick={() => navigate(`/${username}`)}
                      >
                        CANCEL
                      </ButtonDefault>
                      <ButtonPrimary
                        disabled={(!startTime && !startDate) || loading}
                        type="submit"
                      >
                        Confirm
                      </ButtonPrimary>
                    </>
                  </BottomFoot>
                )}
                {/* {step === 3 && <>
                        <ButtonDefault type="button">
                          CANCEL
                        </ButtonDefault>
                        <ButtonPrimary disabled={!startTime && !startDate} onClick={() => setStep(4)} type="button">
                          NEXT
                        </ButtonPrimary>
                      </>} */}
                {/* {step === 4 && <>
                        <ButtonDefault onClick={() => setStep(1)} type="button">
                          Back
                        </ButtonDefault>
                        <ButtonPrimary type="submit">
                          Confirm
                        </ButtonPrimary>
                      </>} */}
              </PickWrapper>
            </Wrapper>
          )}
          {step === 2 && (
            <>
              {availableSlots.calendar_type === "Outlook" ? (
                <EventInfoOutlook
                  event={event}
                  eventInfo={eventInfo}
                  step={step}
                  resetHandler={resetHandler}
                  handleCancel={handleCancel}
                  calendar_type={availableSlots.calendar_type}
                  timezone={watch().timezone}
                />
              ) : (
                <EventInfoGoogle
                  event={event}
                  eventInfo={eventInfo}
                  step={step}
                  resetHandler={resetHandler}
                  handleCancel={handleCancel}
                  calendar_type={availableSlots.calendar_type}
                  timezone={watch().timezone}
                />
              )}
            </>
          )}
          {step === 4 && (
            <CancelEvent
              eventInfo={eventInfo}
              step={step}
              resetHandler={resetHandler}
            />
          )}
        </form>
      )}
    </>
  );
}
