import SectionHeader from "components/SectionHeader";
import { Wrapper, Section, Richtext, TextRow, Heading, Description, CustomDivider } from "./style";
import TwoColumnsGrid from "components/TwoColumnsGrid";
import CardBox from "components/CardBox";

const RichtextSection = ({pTop, pBottom, bgColor}: any) => (
  <Section
    className="RichtextSection"
    sx={{
      backgroundColor: `${bgColor}`,
      paddingTop: `calc(${pTop} * 0.52)`,
      paddingBottom: `calc(${pBottom} * 0.52)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <SectionHeader
        extraClass="headingHead"
        heading="Learn More About 6ix Leads"
      />
      <TwoColumnsGrid
        extraClass="two-columns"
      >
        <CardBox>
          <Richtext>
            <TextRow>
              <Heading
                variant="h3"
              >
                What types of companies can benefit from 6ix Leads?
              </Heading>
              <Description
                variant="body1"
              >
                Companies looking to grow their market cap and liquidity, improve investor engagement, and enhance their fundraising efforts can benefit from using 6ix Leads.
              </Description>
            </TextRow>
            <CustomDivider />
            <TextRow>
              <Heading
                variant="h3"
              >
                How can 6ix Leads benefit my company?
              </Heading>
              <Description
                variant="body1"
              >
                6ix Leads provides an integrated advertising solution that allows you to launch targeted ads to reach the right investors, showcase your company's value proposition, and drive interest in your fundraising efforts. It connects seamlessly with your email list, your website, your profile on 6ix.com, and other properties to capture potential investor contact information, allowing you to send tailored communication and connect with investors online and offline.
              </Description>
            </TextRow>
            <CustomDivider />
            <TextRow>
              <Heading
                variant="h3"
              >
                How do I get started with 6ix Leads?
              </Heading>
              <Description
                variant="body1"
              >
                The best way to get started is by pressing the ‘Get Started” button and using the software to launch a campaign. You can also speak with your dedicated account manager if you have one, or alternatively, request a demo and we will set you up with a dedicated account manager: 6ix.com/demo
              </Description>
            </TextRow>
            <CustomDivider />
            <TextRow>
              <Heading
                variant="h3"
              >
                What are the benefits of using 6ix Leads to launch my campaigns, rather than other platforms?
              </Heading>
              <Description
                variant="body1"
              >
                6ix Leads is custom built for the unique capital markets needs of investor relations professionals. Whereas the modern backbone of the internet - advertising - is built around selling consumer products, and more recently, B2B products, 6ix Leads is the first, and only technology solution custom built to connect investors and companies through digital advertising.
              </Description>
            </TextRow>
          </Richtext>
        </CardBox>
        <CardBox>
          <Richtext>
            <TextRow>
              <Heading
                variant="h3"
              >
                How do I track the performance of my ad campaigns?
              </Heading>
              <Description
                variant="body1"
              >
                6ix Leads natively integrates with your mailing list, so you will be able to see your new investor contacts directly inside of your email marketing tool of choice. Additionally, we provide an end-to-end analytics dashboard with campaign level details.
              </Description>
            </TextRow>
            <CustomDivider />
            <TextRow>
              <Heading
                variant="h3"
              >
                How does 6ix Leads integrate with my existing investor communications efforts and website?
              </Heading>
              <Description
                variant="body1"
              >
                6ix Leads connects seamlessly with your email list, 6ix, and other properties, allowing you to capture potential investor contact information and automatically send tailored communication. This integration helps you keep prospective investors engaged with relevant updates and news, ultimately driving increased interest in your fundraising efforts.
              </Description>
            </TextRow>
            <CustomDivider />
            <TextRow>
              <Heading
                variant="h3"
              >
                Is 6ix Leads suitable for my business?
              </Heading>
              <Description
                variant="body1"
              >
                6ix Leads is built around the problem statement: When seeking to enhance investor visibility and engagement, I want to leverage a targeted and effective advertising solution, so I can reach the right investors, showcase my company's value proposition, and drive interest in my fundraising efforts. If this sounds like you, 6ix Leads is suitable for your business. If this does not sound like you, get in touch and we can direct you to other solutions that may be more suitable for your needs.
              </Description>
            </TextRow>
            <CustomDivider />
            <TextRow>
              <Heading
                variant="h3"
              >
                What kind of support is available for users of 6ix Leads?
              </Heading>
              <Description
                variant="body1"
              >
                We offer comprehensive support for users of 6ix Leads, including access to a dedicated customer success team, product documentation, and ongoing updates to ensure the best possible results.
              </Description>
            </TextRow>
          </Richtext>
        </CardBox>
      </TwoColumnsGrid>
    </Wrapper>
  </Section>
);

export default RichtextSection;