import { styled, Box, Typography, FormControl, Button, ToggleButtonGroup, ToggleButton, TextField, Divider, List } from '@mui/material';

export const Wrapper = styled('div')`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #1F1F1F;
  border-radius: 11px;
  overflow: hidden;
  border: 1px solid #373737;
  max-width: 981px;

  @media (min-width: 768px){
    display: flex;
  }
`;
export const CalendarLeft = styled(Box)`
  background: #131313;
  padding: 24px;
  @media (min-width: 768px){
    min-width:200px;
  }
  @media (min-width: 992px){
    min-width:260px;
  }
`;
export const CalendarRight = styled(Box)`
  @media (min-width: 768px){
    display:flex;
  }
`;
export const CalenderArea = styled(Box)`
  background: linear-gradient(90deg, rgba(43,43,43,1) 0%, rgba(55,55,55,1) 100%);
  padding: 24px;
  height: 100%;
  @media (min-width: 768px){
    width:calc(100% - 200px);
  }
  @media (min-width: 992px){
    width:calc(100% - 262px);
  }
`;
export const SlotArea = styled(Box)`
  //background: linear-gradient(90deg, rgba(43,43,43,1) 0%, rgba(55,55,55,1) 100%);
  padding: 24px;
  @media (min-width: 768px){
    width:262px;
    border-left: 1px solid  #373737;
  }
`;
export const ContentList = styled(Box)`
  padding: 8px 0;
`;
export const ContentListItem = styled(Box)`
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 8px;
  margin: 0 0 12px;
`;
export const ContentListItemIcon = styled(Box)`
  width: 18px;
  height: 18px;
`;
export const ContentListItemText = styled(Box)`
  width: calc(100% - 28px)
`;
export const DayNamesList = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 0 8px;
`;
export const DayName = styled(Box)`
  width: calc(100% / 7);
`;
/* HTML: <div class="loader"></div> */

export const ContentLoader = styled(Box)`
  width: 100%;
  height: 24px;
  margin: 0 0 12px;
  border-radius: 5px;
  background: linear-gradient(-90deg, rgba(22, 22, 22, 0.50) 50%, rgba(43, 43, 43, 0.50) 100%);
  background-size:300% 100%;
  animation: loader 1.5s infinite linear;
  @keyframes loader {
    0% {background-position: right}
    50% {background-position: left}
    100% { background-position: right; }
  }
  &.right-bg {
    background:linear-gradient(270deg, #2B2B2B 0%, #373737 100%);
    background-size:300% 100%;
  }
  &.paragraph {
    height: 60px;
  }
  &.icon {
    height: 18px;
    margin: 0;
  }
  &.mb{
    margin: 0;
  }
  &.dayname {
    margin: 0 0 4px;
    height: 15px;
    background:linear-gradient(270deg, #2B2B2B 0%, #373737 100%);
    background-size:300% 100%;
  }
  &.date {
    margin: 0;
    height: 44px;
    background:linear-gradient(270deg, #2B2B2B 0%, #373737 100%);
    background-size:300% 100%;
  }
  &.slot {
    margin: 0 0 12px;
    height: 44px;
    background: linear-gradient(-90deg, rgba(22, 22, 22, 0.50) 50%, rgba(43, 43, 43, 0.50) 100%);
    background-size:300% 100%;
  }
  &.title {
    background: linear-gradient(-90deg, rgba(22, 22, 22, 0.50) 50%, rgba(43, 43, 43, 0.50) 100%);
    background-size:300% 100%;
  }
`;
export const SideWrapper = styled(Box)`
  padding: 18px;
  background-color: #131313;

  @media (min-width: 768px){
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 206px;
  }
  @media (min-width: 992px){
    padding: 36px;
    width: 256px;
  }
`;

export const TopWrap = styled(Box)`

  @media (min-width: 768px){
    flex-grow: 1;
    min-height: 335px;
  }
`;

export const BottomWrap = styled(Box)`
  padding-top: 40px;
`;

export const Subheading = styled(Typography)`
  color: #8C8E95;
  font-size: 16px;
  line-height: 1.5;
  display: block;
  margin-bottom: 8px;
`;

export const TextNote = styled(Typography)`
  color: #8C8E95;
  font-size: 13px;
  line-height: 1.2;
  display: block;
  margin-top: 10px;

  span{
    color: #c0c0c0;
  }
`;

export const Heading = styled(Typography)`
  color: #E9E9E9;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 16px;

  &.meeting-description {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 500;
  }
`;

export const InfoWidgetWrapper = styled(Box)`
  background-color: #1F1F1F;
  border-radius: 8px;
  padding: 16px;
`;

export const InfoLineText = styled(Typography)`
  color: #E9E9E9;
  font-size: 16px;
  line-height: 1.5;
  display: block;
  position: relative;
  padding-left: 26px;

  + span{
    margin-top: 16px;
  }

  .MuiSvgIcon-root{
    position: absolute;
    width: 18px;
    height: 18px;
    color: #E3C050;
    left: 0;
    top: 3px;
  }
`;

export const FormInlineWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const LabelHeading = styled(Typography)`
  color: #8C8E95;
  font-size: 14px;
  line-height: 1.2;
  display: block;
  margin-bottom: 8px;
`;

export const SelectField = styled(FormControl)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #2B2B2B;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 26px;
  font-size: 12px;
  line-height: 14px;

  &.small{
    width: 64px;

    .MuiSelect-select{
      padding-right: 25px;
      min-height: 1px;
    }
  }

  &:after{
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%) rotate(45deg);
    border: solid currentColor;
    border-width: 0 1px 1px 0;
    content: '';
    width: 6px;
    height: 6px;
    margin-top: -2px;
    pointer-events: none;
  }

  .MuiInputBase-input{
    padding: 3px 10px;
    color: #e9e9e9;
    font-size: 12px;
    line-height: 14px;
    min-height: 1px;
  }

  input{

    + .MuiSvgIcon-root{
      color: #fff;
    }
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }

  .MuiChip-filled{
    background-color: #373737;
    color: #E9E9E9;
    font-size: 14px;
    line-height: 1;
    border-radius: 4px;

    .MuiSvgIcon-root{
      width: 12px;
      height: 12px;
      color: #8C8E95;
    }
  }

  .MuiSelect-icon{
    display: none;
  }
`;

export const PickWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DaterWrapper = styled(Box)`
  width: 100%;

  @media (min-width: 576px){
    display: flex;
  }
`;

export const DatePickerWrapper = styled(Box)`
  width: 100%;
  position: relative;
  
  &,
  .MuiStack-root,
  .MuiStack-root > div,
  .react-datepicker,
  .react-datepicker__month-container,
  .react-datepicker__time-container{
    height: 100%;
  }

  .MuiStack-root{
    padding-top: 0;
  }

  .react-datepicker{
    background-color: #1F1F1F;
    border-radius: 0;
    border: 0;
    display: flex;
    font-family: inherit;
  }

  .react-datepicker__month-container{
    width: 100%;
    padding: 18px;

    @media (min-width: 992px){
      padding: 36px;
    }
  }

  .react-datepicker__time-container{
    width: 40%;
    flex-shrink: 0;
    border-left-color: #373737;

    .react-datepicker__header--time{
      display: none;
    }

    .react-datepicker__time,
    .react-datepicker__time-box{
      height: 100%;
    }

    .react-datepicker__time-list{
      height: 400px !important;
    }

    .react-datepicker__time-box{
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .react-datepicker__header,
  .react-datepicker__time{
    background-color: transparent;
    color: #8C8E95;
  }

  .react-datepicker__header{
    border: 0;
    padding: 0;
  }

  .react-datepicker__current-month{
    text-align: left;
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    line-height: 1.2;
    margin-bottom: 12px;
  }

  .react-datepicker__day-names{
    display: flex;
    margin-bottom: 4px;
  }

  .react-datepicker__day-name{
    color: #8C8E95;
    font-size: 13px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0;
    padding: 8px;
    width: auto;
  }

  .react-datepicker__month{
    margin: 0;
  }

  .react-datepicker__week{
    display: flex;
    gap: 8px;

    + .react-datepicker__week{
      margin-top: 8px;
    }
  }

  .react-datepicker__day{
    background-color: #131313;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: #fff;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0;
    height: 34px;
    font-size: 14px;

    @media (min-width: 992px){
      height: 44px;
      font-size: 16px;
    }

    &.react-datepicker__day--outside-month,
    &.react-datepicker__day--disabled{
      background-color: #2B2B2B;
      color: #575757;
    }

    &.react-datepicker__day--disabled {
      background-color: #2B2B2B;
      color: #575757;
    }

    &.react-datepicker__day--selected,
    &.react-datepicker__day--today.react-datepicker__day--selected{
      position: relative;
      color: #E3C050;
    }

    &.react-datepicker__day--selected:before,
    &.react-datepicker__day--today.react-datepicker__day--selected:before{
      content: '';
      border-radius: 100%;
      width: 4px;
      height: 4px;
      position: absolute;
      left: 50%;
      bottom: 4px;
      transform: translateX(-50%);
      background-image: linear-gradient(45deg, rgba(227,192,80,1) 0%, rgba(227,192,80,1) 100%);
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .react-datepicker__navigation{
    top: 10px;
    right: 20px;
    left: auto;

    @media (min-width: 576px){
      right: 37px;
    }
    @media (min-width: 992px){
      top: 30px;
      right: 48px;
    }
  }

  .react-datepicker__navigation--previous{
    transform: translateX(-80px);
  }

  .react-datepicker__navigation-icon{

    &:before{
      width: 12px;
      height: 12px;
      border-width: 2px 2px 0 0;
      border-color: #E9E9E9;
      top: 6px;
      right: 0;
    }
  }

  .react-datepicker__time-list-item{
    font-size: 16px;
    line-height: 1.2;
    padding: 8px 4px !important;
    height: auto !important;
    border: 1px solid transparent;
    border-radius: 8px !important;
    margin-top: 8px;
    margin-bottom: 8px;

    &.react-datepicker__time-list-item--selected,
    &:hover{
      border-color: #E3C050 !important;
      color: #fff !important;
      background-color: #000 !important;
    }
  }
`;

export const CalendarWrapper = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
  height: 416px;
  width: 100%;
`;

export const TimeWrapper = styled(Box)`
  width: 228px;
  border-left: 1px solid #373737;
  overflow-y: auto;
  overflow-x: hidden;
  height: 416px;
  flex-shrink: 0;
`;

export const InnerWrapper = styled(Box)`
  width: 100%;
  height: 100%;
`;

export const BottomFoot = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  border-top: 1px solid #373737;
  padding: 16px 24px;
  justify-content: center;
`;

export const ButtonDefault = styled(Button)`
  background-color: #1f1f1f;
  border: 1px solid #373737;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 13px 20px;
  color: #fff;
  border-radius: 8px;
  min-width: 94px;

  &.small {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 400;
    border-radius: 6px;
    padding: 5px 12px;
    min-width: inherit;
    
  }

  &.grayed {
    color: #C0C0C0;
    background: #373737;
    border-color: #373737;

    &:hover{
      color: #fff;
      background-color: #2b2b2b;
      border-color: #2b2b2b;
    }
  }

  @media (min-width: 576px){
    min-width: 184px;
  }

  &:hover{
    color: #fff;
    background-color: #454648;
    border-color: #e3c050;
  }
`;

export const ButtonPrimary = styled(Button)`
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 13px 20px;
  min-width: 94px;

  @media (min-width: 576px){
    min-width: 184px;
  }

  &.Mui-disabled{
    color: #8c8e95;
  }

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }
`;

export const TogglerGroupWrapper = styled(ToggleButtonGroup)`
  margin-top: 24px;
`;

export const TogglerGroupButton = styled(ToggleButton)`
  background-color: transparent;
  color: #C0C0C0;
  text-transform: none;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  padding: 8px 16px;

  + button{
    border-left-color: #E3C050;
  }
  
  &.Mui-selected,
  &:hover{
    background-color: #000;
    color: #fff;
    border-color: #E3C050;
  }
`;

export const FormField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 46px;
  font-size: 16px;
  line-height: 18px;

  &.in-chip{
    height: auto;
    min-height: 46px;
    margin-top: 11px;

    > .MuiOutlinedInput-root{
      padding: 4px 34px 4px 4px;
    }

    .MuiChip-filled{
      background-color: #373737;
      color: #E9E9E9;
      font-size: 14px;
      line-height: 1;
      border-radius: 4px;
  
      .MuiSvgIcon-root{
        width: 12px;
        height: 12px;
        color: #8C8E95;
      }
    }

    .MuiAutocomplete-clearIndicator{
      color: #c0c0c0;
      visibility: visible;
  
      .MuiSvgIcon-root{
        width: 16px;
        height: 16px;
      }
    }
  }

  input{
    padding: 8px 12px;
    color: #e9e9e9;
    font-size: 16px;
    line-height: 18px;

    &[type="number"]{
      -moz-appearance: textfield;
      
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }
`;

export const TextAreaField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 120px;
  font-size: 16px;
  line-height: 18px;

  textarea, input{
    padding: 8px 12px;
    color: #e9e9e9;
    font-size: 16px;
    line-height: 18px;
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  > div{
    display: block;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }
`;

export const ScheduleSuccessWrapper = styled(Box)`
  border: 1px solid #373737;
  border-radius: 11px;
  background-color: #1F1F1F;
  padding: 18px;
  width: 100%;
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px){
    padding: 24px;
  }
`;

export const HeadWrap = styled(Box)`
  text-align: center;
`;

export const DescrRow = styled(Box)`
  width: 100%;

  + div{
    margin-top: 10px;
  }
`;

export const HeadIcn = styled(`i`)`
  background-color: rgba(17, 181, 133, 0.3);
  color: #11B585;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  margin: 0 auto 18px;
  line-height: 0;

  @media (min-width: 768px){
    margin-bottom: 29px;
  }

  &.error{
    background-color: rgba(230, 65, 53, 0.3);
    color: #E64135;
  }
  
  .MuiSvgIcon-root{
    width: 22px;
    height: 22px;
  }
`;

export const HeadHeading = styled(Typography)`
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 4px;
  font-weight: 700;
  color: #EDEDED;

  @media (min-width: 768px){
    font-size: 20px;
  }
`;

export const DescrTag = styled(Typography)`
  border-radius: 4px;
  background-color: #E3C050;
  color: #000;
  font-size: 12px;
  line-height: 1;
  padding: 4px 8px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
`;

export const HeadDescr = styled(Typography)`
  font-size: 14px;
  line-height: 1.5;
  color: #C0C0C0;
`;

export const CustomDivider = styled(Divider)`
  border: solid #373737;
  border-width: 1px 0 0;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const InfoList = styled(List)`
  padding-left: 8px;
  padding-right: 8px;

  @media (min-width: 768px){
    padding-left: 16px;
    padding-right: 16px;
  }
  
  > li{
    display: flex;
    align-items: flex-start;
    gap: 5px;
    padding: 0;

    + li{
      margin-top: 16px;
    }
  }
`;

export const ListTitle = styled(Typography)`
  color: #E9E9E9;
  font-size: 14px;
  line-height: 1.5;
  display: block;
  width: 95px;
  font-weight: 700;
  flex-shrink: 0;
  word-break: break-word;

  @media (min-width: 576px){
    width: 135px;
    font-size: 16px;
  }
`;

export const TextBold = styled(Typography)`
  color: #E9E9E9;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  display: block;
  word-break: break-word;

  @media (min-width: 576px){
    font-size: 16px;
  }
`;

export const TextSubtitle = styled(Typography)`
  color: #8C8E95;
  font-size: 14px;
  line-height: 1.5;
  display: block;
  word-break: break-word;

  @media (min-width: 576px){
    font-size: 16px;
  }

  + span{
    margin-top: 10px;
  }
`;

export const InfoTextDescr = styled(Typography)`
  color: #E9E9E9;
  font-size: 14px;
  line-height: 1.5;
  word-break: break-word;

  @media (min-width: 576px){
    font-size: 16px;
  }

  &.cut{
    text-decoration: line-through;
    color: #8C8E95;
  }
`;

export const ListDescr = styled(Box)`
  color: #E9E9E9;
  font-size: 14px;
  line-height: 1.5;
  display: block;
  flex-grow: 1;
  word-break: break-word;

  @media (min-width: 576px){
    font-size: 16px;
  }

  .text-dull{
    color: #8C8E95;
  }
`;

export const TextBack = styled(Typography)`
  color: #E9E9E9;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  word-break: break-word;

  @media (min-width: 576px){
    font-size: 16px;
  }
`;

export const ButtonLink = styled(Button)`
  background-color: transparent;
  color: #E9E9E9;
  text-decoration: underline;
  border: 0;
  border-radius: 0;
  padding: 0;
  text-transform: none;
  min-width: 1px;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;

  @media (min-width: 576px){
    font-size: 16px;
  }

  &:hover{
    background-color: transparent;
    color: #E9E9E9;
    text-decoration: none;
  }
`;

export const WrapFoot = styled(Typography)`
  
  > button{
    display: block;
    width: 100%;
  }
`;

export const IcnWrap = styled(`i`)`
  color: #8C8E95;
  line-height: 0;

  .MuiSvgIcon-root{
    width: 16px;
    height: 16px;
  }
`;

export const ScatList = styled(List)`
  
  > li{
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
    color: #E9E9E9;

    + li{
      margin-top: 8px;
    }
  }
`;

export const SimpleSelectField = styled(FormControl)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  display: block;
  width: 100%;
  height: auto;
  font-size: 16px;
  line-height: 19px;

  &:after{
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%) rotate(45deg);
    border: solid currentColor;
    border-width: 0 1px 1px 0;
    content: '';
    width: 8px;
    height: 8px;
    margin-top: -2px;
    pointer-events: none;
  }

  &.timezone{
    color: #E9E9E9;

    > div,
    > div > div[class*="control"]{
      min-height: 1px;
      height: auto;
      padding: 0;

      &:focus {
        box-shadow: 0 0 0 1px #E3C050 !important;
      }
    }

    > div > div[class*="css-t3"] {
      box-shadow: 0 0 0 1px #E3C050 !important;
    }

    > div > div[class*="control"]{
      background-color: transparent;
      color: #e9e9e9;
      border: 0;

      &:focus {
        box-shadow: 0 0 0 1px #E3C050 !important;
      }
    }

    [class*="IndicatorsContainer"]{
      display: none;
    }

    [class*="singleValue"]{
      color: #E9E9E9;
      padding-right: 20px;
    }

    [class*="ValueContainer"]{
      padding-left: 0;
      padding-right: 0;
    }

    [class*="-menu"]{
      background-color: #131313;
      border: 1px solid #373737;
      margin-top: 0;

      > * > *{

        &:hover{
          background-color: #454648;
          color: #fff;
        }
      }
    }
  }

  .MuiInputBase-input{
    padding: 10px 12px 6px;
    color: #e9e9e9;
    font-size: 16px;
    line-height: 19px;
  }

  input{

    + .MuiSvgIcon-root{
      color: #fff;
    }
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }

  .MuiChip-filled{
    background-color: #373737;
    color: #E9E9E9;
    font-size: 14px;
    line-height: 1;
    border-radius: 4px;

    .MuiSvgIcon-root{
      width: 12px;
      height: 12px;
      color: #8C8E95;
    }
  }
`;

export const NexterWrap = styled(Box)`
  padding: 36px;
`;

export const FormGroup = styled(Box)`
  

  + div{
    margin-top: 24px;
    position: relative;
  }

  .item-holder {
    position: relative;

    + .item-holder {
      margin-top: 10px;
    }
  }

  .btn-trash {
    position: absolute;
    z-index: 99;
    right: 10px;
    top: 3px;
    color: #8c8e95;
    background: transparent;
  }
`;

export const InlineProfilerWrap = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export const DragButton = styled(Button)`
  min-width: 1px;
  background-color: transparent;
  border: 0;
  padding: 0;
  border-radius: 0;
  color: #575757;

  &:hover{
    color: #e9e9e9;
    background-color: transparent;
  }
`;

export const InfoPicWrap = styled(Box)`
  width: 30px;
  height: 30px;
  background-color: #484848;
  border: 1px solid #575757;
  position: relative;
  border-radius: 100%;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const SpanCheck = styled(`span`)`
  border-radius: 100%;
  background-color: #575757;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: #C0C0C0;

  .MuiSvgIcon-root{
    width: 7px;
    height: 7px;
  }
`;

export const InfoText = styled(Typography)`
  color: #E9E9E9;
  font-size: 14px;
  line-height: 1.2;
`;

export const ButtonTertiary = styled(Button)`
  font-size: 13px;
  line-height: 1.2;
  font-weight: 700;
  color: #E9E9E9;
  min-width: 1px;
  background-color: #454648;
  border: 1px solid #454648;
  border-radius: 4px;
  padding: 4px 8px;
  text-transform: none;

  &:hover{
    color: #fff;
    background-color: #000;
    border-color: #E3C050;
  }
`;

export const ListIcons = styled(List)`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 0;
  margin-right: -10px;

  > li{
    padding: 0;
    width: auto;
    display: block;
  }
`;

export const ButtonSelector = styled(Button)`
  border: 1px solid #373737;
  color: #8C8E95;
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  padding: 4px 8px;
  border-radius: 6px;
  background-color: transparent;
  text-transform: none;
  gap: 4px;

  &:hover{
    color: #fff;
    background-color: #000;
    border-color: #E3C050;
  }
`;

export const Icn = styled(`span`)`
  width: 16px;
  height: 16px;

  img{
    height: auto;
    max-width: 100%;
  }
`;

export const TuneTimeSelect = styled(Box)`
  border-top: 1px solid #373737;

  @media (min-width: 576px){
    flex-shrink: 0;
    border-top: 0;
    border-left: 1px solid #373737;
    width: 202px;
  }
  @media (min-width: 992px){
    width: 292px;
  }

  .MuiButtonBase-root{
    display: block;
    padding: 11px 16px;
    background-color: #2B2B2B;
    border: 1px solid #373737;
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    min-width: 1px;
    width: 100%;

    &.MuiButton-outlined,
    &:hover{
      background-color: #000;
      border-color: #E3C050;
    }
  }
`;

export const TuneScrollWrap = styled(Box)`
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 18px 18px;

  @media (min-width: 992px){
    padding: 0 36px 36px;
    max-height: 360px;
  }

  > div{

    + div{
      margin-top: 8px;
    }
  }
`;

export const TextSelectedDate = styled(Typography)`
  color: #fff;
  font-size: 13px;
  line-height: 1.2;
  display: block;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
  padding: 16px 16px 0;

  @media (min-width: 992px){
    margin-bottom: 24px;
    font-size: 17px;
    padding: 36px 36px 0;
  }
`;