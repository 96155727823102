import { styled, List } from "@mui/material";

export const ListWrapper = styled(List)`
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  gap: 7px;

  &.active{

    .MuiListItem-root{
      color: #E3C050;
    }
  }
  
  .MuiListItem-root{
    padding: 0;
    border: 0;
    width: auto;
  }

  .MuiSvgIcon-root{
    width: 19px;
    height: 19px;
  }
`;