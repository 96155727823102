import { styled, Box, Typography, Divider, Button } from '@mui/material';

export const Wrapper = styled('div')`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px){
    padding: 32px;
  }
`;

export const TopWrapper = styled(Box)`
  flex-grow: 1;
`;

export const TagWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 11px;
`;

export const TypeHeading = styled(Typography)`
  background-color: #373737;
  border-radius: 6px;
  padding: 7px 12px 5px;
  color: #E3C050;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 2px;
`;

export const ExcerptText = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  line-height: 1.35;
`;

export const PriceText = styled(Typography)`
  font: 800 30px/1.25 'Playfair Display', 'Times New Roman', Times, serif;
  margin-top: 24px;

  @media (min-width: 768px){
    font-size: 36px;
  }
`;

export const PercentText = styled(Typography)`
  display: block;
  margin-top: 8px;
  font-size: 16px;
  line-height: 1.35;
  color: #C0C0C0;
`;

export const CustomDivider = styled(Divider)`
  border: solid #1f1f1f;
  border-width: 1px 0 0;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const ButtonPrimary = styled(Button)`
  margin-top: 32px;
  display: block;
  min-width: 1px;
  width: 100%;
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 16px 20px;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }
`;