import React from "react";
import { Wrapper, NavWrapper, Logo } from './style';
import logo from "../../assets/images/6ix-meetings-logo.svg";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

const PublicHeader = () => {
  
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&amp;family=Roboto:wght@100;300;400;500;700;900&amp;display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Wrapper>
        <NavWrapper>
          <Logo href="/">
            <img src={logo} alt="6ix Meetings" width={'86'} height={'34'} />
          </Logo>
        </NavWrapper>
      </Wrapper >
      <Outlet />
    </>
  );
};

export default PublicHeader;
