import { styled, Box } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media (min-width: 768px){
    gap: 44px;
  }
`;

export const ImageHolder = styled(Box)`
  width: 100%;

  @media (min-width: 768px){
    width: auto;
  }
  
  img{
    max-width: 100%;
    height: auto;
  }
`;