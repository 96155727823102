import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CaretIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.0998 13.7795C10.9357 13.9317 10.719 14.0128 10.4985 14.0128C10.2784 14.0128 10.0612 13.9321 9.89677 13.7792L4.36215 8.64081C4.19063 8.48148 4.09848 8.26549 4.09848 8.04352C4.09848 7.82086 4.19119 7.60422 4.36376 7.44474C4.6992 7.13502 5.2305 7.13572 5.56502 7.44618L10.4985 12.0264L15.4319 7.4464C15.7664 7.13586 16.2978 7.13504 16.6332 7.44482C16.9861 7.77092 16.987 8.31381 16.635 8.64075L11.0998 13.7795Z" fill="currentColor"/>
    </SvgIcon>
  );
};