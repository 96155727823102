import {
  CalendarRight,
  ContentLoader,
  DayNamesList,
  DayName,
  CalenderArea,
  SlotArea,
} from "./style";

export const CalenderRightLoader = () => {
  return (
    <CalendarRight>
      <CalenderArea>
        <ContentLoader className="right-bg"></ContentLoader>
        <DayNamesList>
          <DayName>
            <ContentLoader className="dayname"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="dayname"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="dayname"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="dayname"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="dayname"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="dayname"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="dayname"></ContentLoader>
          </DayName>
        </DayNamesList>
        <DayNamesList>
          <DayName></DayName>
          <DayName></DayName>
          <DayName></DayName>
          <DayName></DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
        </DayNamesList>
        <DayNamesList>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
        </DayNamesList>
        <DayNamesList>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
        </DayNamesList>
        <DayNamesList>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
        </DayNamesList>
        <DayNamesList>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
          <DayName>
            <ContentLoader className="date"></ContentLoader>
          </DayName>
        </DayNamesList>
        <DayNamesList>
        <DayName>
          <ContentLoader className="date"></ContentLoader>
        </DayName>
        <DayName>
          <ContentLoader className="date"></ContentLoader>
        </DayName>
        <DayName>
          <ContentLoader className="date"></ContentLoader>
        </DayName>
        <DayName>
          <ContentLoader className="date"></ContentLoader>
        </DayName>
        <DayName>
          <ContentLoader className="date"></ContentLoader>
        </DayName>
        <DayName>
          <ContentLoader className="date"></ContentLoader>
        </DayName>
        <DayName></DayName>
      </DayNamesList>
      </CalenderArea>
      <SlotArea>
        <ContentLoader className="title"></ContentLoader>
        <ContentLoader className="slot"></ContentLoader>
        <ContentLoader className="slot"></ContentLoader>
        <ContentLoader className="slot"></ContentLoader>
        <ContentLoader className="slot"></ContentLoader>
        <ContentLoader className="slot"></ContentLoader>
        <ContentLoader className="slot"></ContentLoader>
      </SlotArea>
    </CalendarRight>
  );
};
