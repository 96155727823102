import { styled, Typography } from '@mui/material';

export const Wrapper = styled('div')`
  margin-bottom: 29px;
`;

export const Heading = styled(Typography)`
  font: 900 36px/1.167 'Playfair Display', 'Times New Roman', Times, serif;

  @media (min-width: 992px){
    font-size: 40px;
  }
  @media (min-width: 1200px){
    font-size: 48px;
  }

  &.large{
    
    @media (min-width: 1200px){
      font-size: 50px;
    }
  }
`;

export const Tagline = styled(Typography)`
  color: #E3C050;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  margin-bottom: 10px;
  display: block;
  letter-spacing: 2px;
  text-transform: uppercase;
`;