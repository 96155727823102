import { Wrapper } from "./style";

const ThreeColumnsGrid = ({ children, sameheight }: any) => (
  <Wrapper
    className={sameheight ? 'sameheight' : ''}
  >
    {children}
  </Wrapper>
);

export default ThreeColumnsGrid;