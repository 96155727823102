import { Wrapper, Section, MainHeading, ImageWrapper, WrapperContainer } from "./style";
import RotaryCard from "components/RotaryCard";
import RotaryCardsWrapper from "components/RotaryCardsWrapper";
import IconImage1 from "../../assets/images/img-icon-01.svg"
import IconImage2 from "../../assets/images/img-icon-02.svg"
import IconImage3 from "../../assets/images/img-icon-03.svg"
import IconImage4 from "../../assets/images/img-icon-04.svg"
import IconImage5 from "../../assets/images/img-icon-05.svg"
import IconImage6 from "../../assets/images/img-icon-06.svg"
import IconImage7 from "../../assets/images/img001.png"

const ConditionsSection = ({pTop, pBottom, bgColor}: any) => (
  <Section
    className="ConditionsSection"
    sx={{
      backgroundColor: `${bgColor}`,
      paddingTop: `calc(${pTop} * 0.52)`,
      paddingBottom: `calc(${pBottom} * 0.52)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <WrapperContainer>
        <MainHeading
          variant="h2"
        >
          Warning: You'll hate 6ix Leads if&hellip;
        </MainHeading>
      </WrapperContainer>
      {/* <RotaryCardsWrapper>
        <RotaryCard
          heading=" "
          imageUrl={IconImage1}
          width="110"
          height="143"
        />
        <RotaryCard
          heading="You like generic ad campaigns."
          imageUrl={IconImage2}
          width="167"
          height="122"
        />
        <RotaryCard
          heading="You dismiss data-driven insights."
          imageUrl={IconImage3}
          width="167"
          height="122"
        />
        <RotaryCard
          heading="Outdated content pleases you."
          imageUrl={IconImage4}
          width="167"
          height="122"
        />
        <RotaryCard
          heading="Overspending on ads is your thing."
          imageUrl={IconImage5}
          width="185"
          height="106"
        />
        <RotaryCard
          heading=" "
          imageUrl={IconImage6}
          width="165"
          height="159"
        />
      </RotaryCardsWrapper> */}
      <ImageWrapper>
        <img src={IconImage7} alt="" />
      </ImageWrapper>
    </Wrapper>
  </Section>
);

export default ConditionsSection;