import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LogoutIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5643 4.63959C11.8444 4.39949 12.2662 4.43193 12.5063 4.71204L14.9063 7.51204C15.1207 7.7622 15.1207 8.13134 14.9063 8.3815L12.5063 11.1815C12.2662 11.4616 11.8444 11.4941 11.5643 11.254C11.2842 11.0139 11.2518 10.5922 11.4919 10.312L13.5193 7.94677L11.4919 5.5815C11.2518 5.30139 11.2842 4.87968 11.5643 4.63959Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.93164 7.81558C4.93164 7.44666 5.23071 7.14758 5.59964 7.14758H13.5996C13.9686 7.14758 14.2676 7.44666 14.2676 7.81558C14.2676 8.18451 13.9686 8.48358 13.5996 8.48358H5.59964C5.23071 8.48358 4.93164 8.18451 4.93164 7.81558Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9099 0.931885C12.2788 0.931796 12.578 1.2308 12.5781 1.59972C12.5782 1.96865 12.2792 2.2678 11.9102 2.26788L4.30294 2.26971C4.30287 2.26971 4.303 2.26971 4.30294 2.26971C3.59623 2.27043 3.06842 2.81497 3.06842 3.43068V12.5709C3.06842 12.8702 3.19181 13.1628 3.42051 13.3827C3.65009 13.6035 3.96685 13.7319 4.30235 13.7319H12.0004C12.3693 13.7319 12.6684 14.031 12.6684 14.3999C12.6684 14.7688 12.3693 15.0679 12.0004 15.0679H4.30235C3.62901 15.0679 2.97825 14.8109 2.49446 14.3457C2.00979 13.8796 1.73242 13.2418 1.73242 12.5709V3.43068C1.73242 2.02726 2.90927 0.934927 4.30219 0.933714L11.9099 0.931885Z" fill="currentColor" />
    </SvgIcon>
  );
};