import { PlayIcon } from "components/Icons/PlayIcon";
import { Align, CaptionHeading, CaptionWrapper, Heading, ImageWrapper, PlayButton, Wrapper } from "./style";

const FeaturedVideo = ({imageUrl, heading, caption}: any) => (
  <Wrapper>
    <ImageWrapper>
      <img src={imageUrl} alt="" />
    </ImageWrapper>
    <CaptionWrapper>
      <Align>
        <Heading
          variant="h3"
        >
          {heading}
        </Heading>
        {caption &&
          <CaptionHeading
            variant="h4"
          >
            {caption}
          </CaptionHeading>
        }
      </Align>
    </CaptionWrapper>
    <PlayButton>
      <PlayIcon />
    </PlayButton>
  </Wrapper>
);

export default FeaturedVideo;