import { TextItem, Wrapper } from "./style";

const StatusesList = ({}: any) => (
  <Wrapper>
    <TextItem
      className="warning"
      variant="caption"
    >
      Major new features
    </TextItem>
    <TextItem
      className="primary"
      variant="caption"
    >
      Company news
    </TextItem>
    <TextItem
      className="secondary"
      variant="caption"
    >
      Something cool happened
    </TextItem>
  </Wrapper>
);

export default StatusesList;