import { styled, Box, Typography, Divider, List } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 24px 48px;

  @media (min-width: 768px){
    gap: 40px;
  }
  @media (min-width: 992px){
    gap: 100px;
  }
`;

export const Col = styled(Box)`
  width: 100%;

  @media (min-width: 640px){
    width: calc(50% - 20px);
  }
  @media (min-width: 992px){
    width: calc(50% - 50px);
  }
`;

export const Separator = styled(Divider)`
  border-top: 5px solid rgba(74, 74, 74, 0.5);
  margin-bottom: 26px;

  @media (min-width: 768px){
    display: none;
  }
`;

export const Heading = styled(Typography)`
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.25;
  margin-bottom: 24px;

  @media (min-width: 768px){
    font-size: 20px;
  }
`;

export const ListWrapper = styled(List)`
  padding-top: 0;
  padding-bottom: 0;
  
  .MuiListItem-root{
    border-top: 1px solid #373737;
    padding: 11px 0;
  }

  .MuiTypography-root{
    font-size: 14px;
    line-height: 1.5;
    color: #c0c0c0;

    @media (min-width: 768px){
      font-size: 18px;
    }
  }
`;