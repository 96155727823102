import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const PencilIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M12.232 3.22644C12.3989 3.05954 12.597 2.92715 12.8151 2.83683C13.0331 2.7465 13.2668 2.70001 13.5029 2.70001C13.7389 2.70001 13.9726 2.7465 14.1907 2.83683C14.4087 2.92715 14.6069 3.05954 14.7738 3.22644C14.9407 3.39334 15.0731 3.59147 15.1634 3.80953C15.2537 4.0276 15.3002 4.26131 15.3002 4.49734C15.3002 4.73337 15.2537 4.96709 15.1634 5.18515C15.0731 5.40321 14.9407 5.60135 14.7738 5.76825L6.19518 14.3468L2.7002 15.3L3.65337 11.805L12.232 3.22644Z" stroke="currentColor" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};