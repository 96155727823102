import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const StarFilledIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.73755 1.36489C10.1978 0.400181 11.571 0.400182 12.0312 1.36489L14.0865 5.67295C14.2682 6.05381 14.6271 6.31964 15.0444 6.38237L19.7095 7.08362C20.7384 7.23827 21.1555 8.49618 20.4229 9.2349L16.9933 12.6932C16.7059 12.9829 16.5752 13.3931 16.642 13.7957L17.4441 18.6337C17.6172 19.6776 16.5131 20.4633 15.5835 19.9578L11.4915 17.7323C11.1129 17.5264 10.6558 17.5264 10.2773 17.7323L6.1853 19.9578C5.2557 20.4633 4.15159 19.6776 4.32467 18.6337L5.12678 13.7957C5.19353 13.3931 5.06283 12.9829 4.77546 12.6932L1.34587 9.2349C0.613286 8.49618 1.0304 7.23827 2.05922 7.08362L6.72432 6.38237C7.14162 6.31964 7.50056 6.05381 7.68227 5.67295L9.73755 1.36489Z" fill="currentColor"/>
    </SvgIcon>
  );
};