import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const PlusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.668 6.99978C11.668 7.32259 11.4063 7.58428 11.0835 7.58428L2.9168 7.58428C2.59399 7.58428 2.3323 7.32259 2.3323 6.99978C2.3323 6.67697 2.59399 6.41528 2.9168 6.41528L11.0835 6.41528C11.4063 6.41528 11.668 6.67697 11.668 6.99978Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00046 2.33228C7.32327 2.33228 7.58496 2.59396 7.58496 2.91678L7.58496 11.0834C7.58496 11.4063 7.32327 11.6679 7.00046 11.6679C6.67765 11.6679 6.41596 11.4063 6.41596 11.0834L6.41596 2.91678C6.41596 2.59397 6.67765 2.33228 7.00046 2.33228Z" fill="currentColor"/>
    </SvgIcon>
  );
};