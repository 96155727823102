import CardBox from "components/CardBox";
import { Description, Heading, ImageHolder, Wrapper } from "./style";

const ProductColumn = ({imageUrl, heading, excerpt, width, height}: any) => (
  <Wrapper>
    <ImageHolder>
      <img src={imageUrl} alt={heading} width={width} height={height} />
    </ImageHolder>
    <Heading
      variant="h3"
    >
      {heading}
    </Heading>
    {excerpt &&
      <Description
        variant="body1"
      >
        {excerpt}
      </Description>
    }
  </Wrapper>
);

export default ProductColumn;