import { Box, Typography } from "@mui/material";
import { Wrapper, Section, DescriptionText } from "./style";
import SectionHeader from "components/SectionHeader";
import FourColumnsGrid from "components/FourColumnsGrid";
import CardBox from "components/CardBox";
import IconColumn from "components/IconColumn";
import { SearchIcon } from "components/Icons/SearchIcon";
import { PencilIcon } from "components/Icons/PencilIcon";
import { EmailIcon } from "components/Icons/EmailIcon";
import { EnrollIcon } from "components/Icons/EnrollIcon";

const IconsSection = ({pTop, pBottom, bgColor}: any) => (
  <Section
    className="IonsSection"
    sx={{
      backgroundColor: `${bgColor}`,
      paddingTop: `calc(${pTop} * 0.52)`,
      paddingBottom: `calc(${pBottom} * 0.52)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <SectionHeader heading="How 6ix Leads Works">
        <DescriptionText
            variant="body1"
          >
          Follow this playbook to get investor attention, attract capital and grow your reputation
        </DescriptionText>
      </SectionHeader>
      <FourColumnsGrid>
        <CardBox>
          <IconColumn heading="Set a campaign goal such as generating investor meetings or capturing emails" icon={<SearchIcon />} />
        </CardBox>
        <CardBox>
          <IconColumn heading="Upload your own creative and copy or work with our team to create the campaign" icon={<PencilIcon />} />
        </CardBox>
        <CardBox>
          <IconColumn heading="Go live with a small campaign and scale your budget as the ad performs" icon={<EmailIcon />} />
        </CardBox>
        <CardBox>
          <IconColumn heading="Respond to investors requesting meetings and requesting more information" icon={<EnrollIcon />} />
        </CardBox>
      </FourColumnsGrid>
    </Wrapper>
  </Section>
);

export default IconsSection;