import CardBox from "components/CardBox";
import { Heading, Wrapper } from "./style";

// status: 'active' for other view
const FeaturesListColumn = ({ heading, status, hasBorder, children, asIndividual }: any) => (
  <Wrapper
    className={`${
      status === 'active' ? 'emphasized' : ''
    } ${
      hasBorder ? 'bordered' : ''
    } ${
      asIndividual ? 'featured' : ''
    }`}
  >
    <Heading
      variant="h2"
    >
      {heading}
    </Heading>
    {children}
  </Wrapper>
);

export default FeaturesListColumn;