import { styled, Typography, Box, Button } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
`;

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1091px;
  margin-left: auto;
  margin-right: auto;
`;

export const ColsWrapper = styled(Box)`

  @media (min-width: 768px){
    display: flex;
  }
`;

export const SectionHeading = styled(Typography)`
  font: 800 30px/1.25 'Playfair Display', 'Times New Roman', Times, serif;
  margin-bottom: 48px;

  @media (min-width: 992px){
    font-size: 35px;
  }
  @media (min-width: 1200px){
    font-size: 40px;
  }
`;

export const MainImageWrapper = styled(Box)`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  @media (min-width: 768px){
    margin: 0;
    max-width: none;
  }

  img{
    max-width: 100%;
    height: auto;
  }
`;

export const Col = styled(Box)`
  width: 100%;

  @media (min-width: 768px){
    display: flex;
  }

  &:first-child{

    @media (min-width: 768px){
      flex-shrink: 0;
      width: 52%;
    }
    @media (min-width: 992px){
      width: 61%;
    }
  }
`;

export const Align = styled(Box)`
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
`;

export const ColumnsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

export const ReportColumn = styled(Box)`
  width: calc(33.333% - 10px);
`;

export const ImageHolder = styled(Box)`
  margin-bottom: 20px;

  img{
    max-width: 100%;
    height: auto;
  }
`;

export const Heading = styled(Typography)`
  color: #C0C0C0;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.18;
  margin-bottom: 16px;

  @media (min-width: 768px){
    font-size: 20px;
  }
  @media (min-width: 1200px){
    font-size: 22px;
  }
`;

export const Subheading = styled(Typography)`
  font-weight: 700;
  font-size: 20px;
  line-height: 1.18;

  @media (min-width: 768px){
    font-size: 22px;
  }
  @media (min-width: 1200px){
    font-size: 24px;
  }
`;

export const ButtonPrimary = styled(Button)`
  margin-top: 48px;
  background-color: #000;
  color: #fff;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #E3C050;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
  text-decoration: none;
  transition: all 0.3s ease;
  text-transform: capitalize;

  @media (min-width: 768px){
    padding-left: 36px;
    padding-right: 36px;
  }

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }
`;