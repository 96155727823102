import SectionHeader from "components/SectionHeader";
import { Wrapper, Section } from "./style";
import Image1 from "../../assets/img-icon-07.svg"
import LogosList from "components/LogosList";

const SponsorsSection = ({pTop, pBottom, bgColor}: any) => (
  <Section
    className="SponsorsSection"
    sx={{
      backgroundColor: `${bgColor}`,
      paddingTop: `calc(${pTop} * 0.52)`,
      paddingBottom: `calc(${pBottom} * 0.52)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <SectionHeader
        tagline="POWERING THE WORLD’S BEST IR professionals"
        heading="Trusted by more than 400 Public Companies"
      />
      <LogosList />
    </Wrapper>
  </Section>
);

export default SponsorsSection;