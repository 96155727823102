import CardBox from "components/CardBox";
import { Description, Heading, ImageHolder, Wrapper, TaglineHeading, DescriptionWrapper } from "./style";

const PostColumn = ({imageUrl, heading, tagline, excerpt, view}: any) => (
  <Wrapper
    className={view === 'list' ? 'post-column--list-view' : ''}
  >
    <ImageHolder>
      <img src={imageUrl} alt={heading} />
    </ImageHolder>
    <DescriptionWrapper>
      {tagline &&
        <TaglineHeading
          variant="caption"
        >
          {tagline}
        </TaglineHeading>
      }
      <Heading
        variant="h3"
      >
        {heading}
      </Heading>
      {excerpt &&
        <Description
          variant="body1"
        >
          {excerpt}
        </Description>
      }
    </DescriptionWrapper>
  </Wrapper>
);

export default PostColumn;