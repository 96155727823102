import { styled, Typography, Box } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
`;

export const Wrapper = styled(Box)`
`;

export const WrapperContainer = styled(Box)`
  width: 100%;
  max-width: 1121px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
`;

export const MainHeading = styled(Typography)`
  font: 800 36px/1.167 'Playfair Display', 'Times New Roman', Times, serif;
  margin-bottom: 20px;

  @media (min-width: 768px){
    margin-bottom: 60px;
  }
  @media (min-width: 1200px){
    font-size: 40px;
  }
  @media (min-width: 1530px){
    font-size: 50px;
  }
`;

export const ImageWrapper = styled(Box)`
  img{
    width: 100%;
    height: auto;
  }
`;