import { Wrapper, Section, Col, Description, Heading, ImageHolder, AlignHolder, AlignBox } from "./style";
import Image from "../../assets/images/img05.png"
import RatingStartList from "components/RatingStartList";
import { ListItem } from "@mui/material";
import { StarFilledIcon } from "components/Icons/StarFilledIcon";

const UpdatesSection = ({pTop, pBottom, bgColor}: any) => (
  <Section
    className="UpdatesSection"
    sx={{
      backgroundColor: `${bgColor}`,
      paddingTop: `calc(${pTop} * 0.52)`,
      paddingBottom: `calc(${pBottom} * 0.52)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <AlignHolder>
        <Col>
          <AlignBox>
            <Heading
              variant="h2"
            >
              The fastest most loved IR advertising solution on the planet
            </Heading>
            <Description
              variant="body1"
            >
              6ix Leads is the only advertising platform that combines precision targeting with real-time campaign adaptability, ensuring that you are reaching investors with the most compelling message. Through our platform, companies gain unparalleled insights into investor engagement, sentiment and preferences. With 6ix Leads, you're not just advertising; you're strategically connecting with qualified potential investors.
            </Description>
            <RatingStartList status="active">
              <ListItem>
                <StarFilledIcon />
              </ListItem>
              <ListItem>
                <StarFilledIcon />
              </ListItem>
              <ListItem>
                <StarFilledIcon />
              </ListItem>
              <ListItem>
                <StarFilledIcon />
              </ListItem>
              <ListItem>
                <StarFilledIcon />
              </ListItem>
            </RatingStartList>
          </AlignBox>
        </Col>
        <Col>
          <AlignBox>
            <ImageHolder>
              <img src={Image} alt="" />
            </ImageHolder>
          </AlignBox>
        </Col>
      </AlignHolder>
    </Wrapper>
  </Section>
);

export default UpdatesSection;