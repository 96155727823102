import { styled } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;

  @media (min-width: 992px){
    gap: 48px;
  }

  > div{
    width: 100%;

    @media (min-width: 768px){
      width: calc(50% - 12px);
    }
    @media (min-width: 992px){
      width: calc(50% - 24px);
    }
  }
`;