import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const EnrollIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M4 12.4609V20.4609C4 20.9914 4.21071 21.5001 4.58579 21.8752C4.96086 22.2502 5.46957 22.4609 6 22.4609H18C18.5304 22.4609 19.0391 22.2502 19.4142 21.8752C19.7893 21.5001 20 20.9914 20 20.4609V12.4609" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M16 6.46094L12 2.46094L8 6.46094" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M12 2.46094V15.4609" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};