import { styled, Box, Typography } from '@mui/material';

export const Wrapper = styled('div')`
  padding: 24px;

  @media (min-width: 768px){
    padding: 32px;
  }
`;

export const ImageHolder = styled(Box)`
  margin-bottom: 24px;
  display: flex;
  min-height: 40px;

  img{
    margin-top: auto;
    margin-bottom: auto;
    max-width: 100%;
    height: auto;
  }
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  line-height: 1.35;

  @media (min-width: 768px){
    font-size: 22px;
  }
`;

export const Description = styled(Typography)`
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.5;
  color: #C0C0C0;

  @media (min-width: 768px){
    font-size: 18px;
  }
`;