import axios from "axios";
import {
  ButtonPrimary,
  ButtonWrapper,
  FormField,
  FormGroup,
  LabelHeading,
  TextAreaField,
  Wrapper,
} from "./style";
//import { useSendEmailMutation } from "api/courses";
import { toast } from "react-toastify";

const ContactForm = ({}: any) => {
  //const [sendEmail] = useSendEmailMutation();

  const submitHandler = (e: any) => {
    e.preventDefault();

    const email = e.target.email.value;
    const message = e.target.message.value;

    //sendEmail({ email, message }).then((res) => toast.success("Email Sent"));
  };

  return (
    <Wrapper>
      <form onSubmit={submitHandler}>
        <FormGroup>
          <LabelHeading variant="caption">Full Name</LabelHeading>
          <FormField name="name" hiddenLabel />
        </FormGroup>
        <FormGroup>
          <LabelHeading variant="caption">Email</LabelHeading>
          <FormField name="email" hiddenLabel />
        </FormGroup>
        <FormGroup>
          <LabelHeading variant="caption">Message</LabelHeading>
          <TextAreaField name="message" hiddenLabel multiline />
        </FormGroup>
        <ButtonWrapper>
          <ButtonPrimary
            type="submit"
          >
            Send
          </ButtonPrimary>
        </ButtonWrapper>
      </form>
    </Wrapper>
  );
};

export default ContactForm;
