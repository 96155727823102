import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const SearchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M11 19.4609C15.4183 19.4609 19 15.8792 19 11.4609C19 7.04266 15.4183 3.46094 11 3.46094C6.58172 3.46094 3 7.04266 3 11.4609C3 15.8792 6.58172 19.4609 11 19.4609Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M20.9999 21.4608L16.6499 17.1108" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};