import { Wrapper, Section } from "./style";
import Image1 from "../../assets/images/img30.jpg"
import QuoteColumn from "components/QuoteColumn";

const TestimonialsSection = ({pTop, pBottom, bgColor}: any) => (
  <Section
    className="TestimonialsSection"
    sx={{
      backgroundColor: `${bgColor}`,
      paddingTop: `calc(${pTop} * 0.52)`,
      paddingBottom: `calc(${pBottom} * 0.52)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <QuoteColumn
        ImageUrl={Image1}
        text="“We built 6ix Leads so that investor relations professionals and executive teams would be empowered to showcase their investment opportunity to investors online.”"
        author="Daniel Barankin"
        cite="President & CEO of 6ix"
      />
    </Wrapper>
  </Section>
);

export default TestimonialsSection;