import { styled, Typography, Box } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
`;

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1091px;
  margin-left: auto;
  margin-right: auto;
`;

export const SectionHeading = styled(Typography)`
  font: 800 34px/1.25 'Playfair Display', 'Times New Roman', Times, serif;
  margin-bottom: 12px;

  @media (min-width: 768px){
    font-size: 38px;
  }
  @media (min-width: 992px){
    font-size: 42px;
  }
  @media (min-width: 1200px){
    font-size: 48px;
  }

  strong{
    color: #E3C050;
  }
`;

export const Description = styled(Typography)`
  color: #C0C0C0;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;

  @media (min-width: 768px){
    font-size: 18px;
  }
`;

export const LapsWrapper = styled(Box)`
  
  img{
    max-width: 100%;
    height: auto;
  }
`;

export const DateTime = styled(Typography)`
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  display: block;
  margin-bottom: 4px;
`;