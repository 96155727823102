import { Heading, ImageHolder, Wrapper } from "./style";

const CourseColumn = ({imageUrl, heading}: any) => (
  <Wrapper>
    <ImageHolder>
      <img src={imageUrl} alt={heading} />
    </ImageHolder>
    <Heading
      variant="h3"
    >
      {heading}
    </Heading>
  </Wrapper>
);

export default CourseColumn;