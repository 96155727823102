import { ListWrapper } from "./style";

const RatingStartList = ({children, status}: any) => (
  <ListWrapper
    className={status === 'active' ? 'active' : ''}
  >
    {children}
  </ListWrapper>
);

export default RatingStartList;