import { styled, Typography, Box, List } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;

  .headingHead{
    text-align: center;
  }
`;

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1091px;
  margin-left: auto;
  margin-right: auto;
`;

export const DescriptionText = styled(Typography)`
  color: #C0C0C0;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 12px;
`;

export const PriceFeaturesList = styled(List)`
  padding-top: 0;
  padding-bottom: 0;
  color: #c0c0c0;
  
  .MuiListItem-root{
    padding: 4px 0;
    gap: 12px;
    align-items: flex-start;
  }

  .MuiListItemIcon-root{
    min-width: 1px;
    position: relative;
    top: 5px;
  }

  .MuiSvgIcon-root{
    color: #E3C050;
    width: 18px;
    height: 13px;
  }

  .MuiListItemText-root{
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const ListHeading = styled(Typography)`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  display: block;
  margin-bottom: 16px;
  color: #c0c0c0;
`;