import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { Heading, Tagline, Wrapper } from "./style";

const SectionHeader = ({ heading, tagline, children, headingLarge, extraClass }: any) => (
  // <div className={hasBorder ? 'has-border' : ''}>
  <Wrapper
    className={extraClass ? extraClass : ''}
  >
    {tagline &&
      <Tagline
        variant="caption"
      >
        {tagline}
      </Tagline>
    }
    <Heading
      variant="h2"
      className={headingLarge ? 'large' : ''}
    >
      {heading}
    </Heading>
    {children}
  </Wrapper>
  // </div>
);

export default SectionHeader;