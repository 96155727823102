import { DescriptionPara, MainHeading, Wrapper } from "./style";

const SectionMainHeader = ({ heading, description, size, children }: any) => (
  <Wrapper
    sx={{
      maxWidth: size
    }}
  >
    {children &&
      <>
        {children}
      </>
    }
    {heading &&
      <MainHeading
        variant="h1"
      >
        {heading}
      </MainHeading>
    }
    {description &&
      <DescriptionPara
        variant="body1"
      >
        {description}
      </DescriptionPara>
    }
  </Wrapper>
);

export default SectionMainHeader;