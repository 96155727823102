import CardBox from "components/CardBox";
import { Description, Heading, Hr, IconHolder, Wrapper } from "./style";

const IconColumn = ({heading, description, icon}: any) => (
  <Wrapper>
    <IconHolder>
      {icon}
    </IconHolder>
    <Heading
      variant="h3"
    >
      {heading}
    </Heading>
    {description &&
      <>
        <Hr />
        <Description
          variant="body1"
        >
          {description}
        </Description>
      </>
    }
  </Wrapper>
);

export default IconColumn;