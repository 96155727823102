import { Wrapper } from "./style";

const TwoColumnsGrid = ({ children, sameheight, extraClass }: any) => (
  <Wrapper
    className={extraClass ? extraClass : ''}
    sx={{
      alignItems: sameheight === 'false' ? 'center' : 'stretch'
    }}
  >
    {children}
  </Wrapper>
);

export default TwoColumnsGrid;