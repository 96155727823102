import { styled, Typography, Box } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
`;

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1091px;
  margin-left: auto;
  margin-right: auto;
`;