import { styled, Box, Typography, Button } from '@mui/material';

export const Wrapper = styled('div')`
  position: relative;
  border-radius: 11px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 40px;
`;

export const ImageWrapper = styled(Box)`
  border-radius: inherit;

  &:before{
    pointer-events: none;
    z-index: 1;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(135deg, rgba(68,68,68,0.5) 0%,rgba(68,68,68,0) 50%,rgba(68,68,68,0.5) 100%);
  }
  
  img{
    border-radius: inherit;
    width: 100%;
    max-height: auto;
    vertical-align: top;
  }
`;

export const CaptionWrapper = styled(Box)`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Align = styled(Box)`
  width: 100%;
  margin-top: auto;
  padding: 40px 70px 40px 40px;

  @media (min-width: 992px){
    padding-right: 100px;
  }

`;

export const Heading = styled(Typography)`
  font-weight: 700;
  font-size: 20px;
  line-height: 1.35;

  @media (min-width: 768px){
    font-size: 22px;
  }
  @media (min-width: 1200px){
    font-size: 26px;
  }
`;

export const CaptionHeading = styled(Typography)`
  color: #C0C0C0;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  margin-top: 8px;
`;

export const PlayButton = styled(Button)`
  position: absolute;
  right: 28px;
  bottom: 28px;
  z-index: 3;
  border-radius: 100%;
  background-color: rgba(13, 13, 24, 0.5);
  width: 28px;
  height: 28px;
  border: 1px solid rgb(75, 75, 88);
  padding: 0;
  min-width: 1px;
  color: #fff;

  @media (min-width: 768px){
    width: 48px;
    height: 48px;
  }
  @media (min-width: 1200px){
    right: 48px;
    bottom: 48px;
  }

  &:hover{
    background-color: rgb(75, 75, 88);
  }

  .MuiSvgIcon-root{
    margin-left: 1px;
    width: 8px;
    height: 8px;

    @media (min-width: 768px){
      width: 14px;
      height: 14px;
      margin-left: 3px;
    }
  }
`;