import StatusesList from "components/StatusesList";
import { Wrapper, Section, SectionHeading, Description, LapsWrapper, DateTime } from "./style";
import Image1 from "../../assets/images/img37.png"

const ScheduleSection = ({pTop, pBottom}: any) => (
  <Section
    className="ScheduleSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.52)`,
      paddingBottom: `calc(${pBottom} * 0.52)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <SectionHeading
        variant="h2"
      >
        We ship <strong>weirdly fast</strong>
      </SectionHeading>
      <Description
        variant="body1"
      >
        (How else could we have done all this?)
      </Description>
      <StatusesList />
      <LapsWrapper>
        <DateTime
          variant="caption"
        >
          2023
        </DateTime>
        <img src={Image1} alt="" />
      </LapsWrapper>
    </Wrapper>
  </Section>
);

export default ScheduleSection;